import { Flex } from "antd";
import styled from "styled-components";

// export const CustomFormContainer = styled("form")`
export const SlingTableWrap = styled(Flex)`
  width: 100%;
  overflow-y: scroll;
  border-top: 0.1rem solid #e2e2e2;
  margin-top: 1.5rem;

  .web__table {
    width: 100%;
  }
  .ant-table-container {
    width: 100%;
  }

  .ant-table-wrapper {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    padding: 1rem;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .nodata__wrap {
    width: 100%;
    height: 80%;
    padding: 2rem 0 1rem 0;
    .noads__headtitle {
      margin: 0;
      color: var(--blackPrimary);
    }

    .noads__subtitle {
      color: var(--lightGrayPrimary);
      margin: 7px 0 10px 0;
    }
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    min-width: 100%;
  }
  .ant-table {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .ant-table-thead {
    background-color: #ebebebf7;
    border-radius: 0 !important;
    padding: 1rem;
    display: none !important;
  }

  .tablerow__wrap {
    flex-grow: 1;
    width: 100%;
    position: relative;

    ::before {
      background-color: var(--errorPrimary);
      content: "";
      position: absolute;
      left: -1rem;
      top: 0.4rem;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 100%;
    }
    .sling__header {
      margin: 0;
      font-size: 0.9rem;
    }
    .sling__description,
    .sling__time {
      font-size: 0.8rem;
      color: var(--lightGrayPrimary);
    }
    .sling__description {
      margin: 0;
    }

    .sling__description__sub {
      font-size: 0.7rem;
      color: var(--lightGraySecondary);
    }
  }
  .tablerow__wrap__credited {
    ::before {
      background-color: var(--bluePrimary);
    }
  }

  .ant-pagination {
    border: 1px solid var(--lightGraySecondary);
    border-radius: 10rem;
    width: max-content;
    max-width: 100%;
    overflow: hidden;
    .ant-pagination-item {
      padding-top: 4px;
      border-radius: 0;

      a {
        color: var(--darkGrayPrimary);
        font-weight: 300;
      }
    }

    .ant-pagination-item-active {
      border: none;
      background-color: #ebebebf7;

      a {
        color: var(--darkGrayPrimary);
      }
    }
  }

  .ant-table-tbody > tr > td {
    padding: 0.5rem 0 0.8rem 1rem;
  }

  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }
  @media screen and (max-width: 1300px) {
  }

  @media screen and (max-width: 1100px) {
    .tablerow__wrap {
    }
  }

  @media screen and (max-width: 600px) {
    .ant-spin-container {
      align-items: center;
    }

    .ant-pagination {
      .ant-pagination-item {
        padding-top: 7px;
      }
    }

    .tablerow__wrap {
      flex-direction: column;
      align-items: flex-start;

      .sling__time {
      }
    }
  }
`;
