import { Flex, Modal } from "antd";
import styled from "styled-components";

type Custom = {
  copied?: boolean;
};

export const SlingWrap = styled(Flex)`
  padding: 2.5rem 4rem;
  overflow-y: hidden;

  .sling__header {
    font-size: 2rem;
    svg {
      font-size: 1rem;
      color: var(--lightGrayPrimary);
      margin: 0 0 0 0.5rem;
    }
  }

  .view__sling__account {
    color: var(--bluePrimary);
    text-decoration: underline;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .sling__body {
    padding: 0 10%;
    margin-top: 1rem;
    .sling__pricesection {
      .shekels__amount {
        color: var(--blackSecondary);
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        font-weight: 300;

        span {
          font-size: 0.8rem;
          color: var(--lightGrayPrimary);
          margin-left: 0.3rem;
        }
      }
      .amount__card__left {
        width: 50%;
        border: 0.1rem solid #e4e4e4;
        border-radius: 0.5rem;
        padding: 1.7rem 1.5rem;

        .currentbalance__text {
          margin: 0;
          color: var(--lightGrayPrimary);
          font-weight: 600;
        }

        .shekel__rating {
          padding: 0.6rem 1.3rem;
          border-radius: 3rem;
          background-color: var(--blackSecondary);
          color: #fff;
          font-size: 0.9rem;
          margin: 0 0 1rem 0;
        }
      }

      .amount__card__right {
        gap: 1rem;
        width: 50%;
        .amount__card__right__inner {
          width: 100%;
          border: 0.1rem solid #e4e4e4;
          border-radius: 0.5rem;
          padding: 1rem 1.2rem 0.7rem 1.2rem;
          position: relative;

          .rightcard__headertext {
            margin: 0;
            color: var(--lightGrayPrimary);
            font-weight: 600;
            font-size: 0.8rem;
          }

          ::before {
            position: absolute;
            content: "";
            width: 0.2rem;
            height: 50%;
            left: 0;
            top: 25%;
            background-color: var(--bluePrimary);
          }
        }

        .amount__card__right__innerbottom {
          ::before {
            background-color: var(--errorPrimary);
          }
        }
      }
    }

    .table__wrap {
      border: 0.1rem solid #e4e4e4;
      border-radius: 0.5rem;
      padding: 1rem;
      margin-top: 2rem;
      .table__headertext {
        color: var(--blackSecondary);
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 2.5rem 15px;

    .sling__body {
      padding: 0;
      .sling__pricesection {
        flex-direction: column;

        .amount__card__left,
        .amount__card__right {
          width: 100%;
        }
      }
    }
  }
`;

export const AddShekelModalWrap = styled(Modal)<Custom>`
  .ant-modal-content {
    padding: 24px 1rem;
  }
  .adshekels__headersection {
    .adshekels__header {
      font-size: 1.3rem;
      margin: 0;
    }
    .adshekels__subheader {
      color: var(--lightGrayPrimary);
      font-size: 0.7rem;
    }
  }

  .shekel__equavalent {
    font-size: 0.8rem;
  }

  .shekel_breakdown {
    background-color: #fffaf0;
    padding: 16px 24px;
    margin-top: 32px;
    border-radius: 18px;
    .shekel_breakdown_line {
      width: 100%;
      height: 1px;
      background-color: #ffc10724;
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #333;
    }
    .shekel_breakdown_summary {
      font-weight: 600;
    }
  }

  .account__number_section {
    background-color: var(--blackPrimary);
    padding: 1rem 0.7rem;
    border-radius: 1rem;
    gap: 1rem;
    margin: 1rem 0;

    .copy__account__numbertext {
      margin: 0;
      font-size: 0.65rem;
      color: var(--lightGraySecondary);
      line-height: 1rem;
    }
    .bank__section {
      background-color: var(--blackSecondary);
      padding: 1rem;
      width: 60%;
      border-radius: 0.3rem;
      gap: 0.6rem;

      .bank__name {
        font-size: 0.9rem;
        color: #fff;
        margin: 0;
      }
      .account__number {
        font-size: 1.3rem;
        color: #fff;
        margin: 0;
        line-height: 1rem;
        position: relative;

        .copied__text {
          position: absolute;
          background-color: var(--successPrimary);
          color: #fff;
          transition: all 0.3s ease-in-out;
          visibility: ${({ copied }) => (copied ? "visible" : "hidden")};
          font-size: 0.7rem;
          padding: 0 0.4rem;
          right: -3rem;
          border-radius: 0.3rem;
          top: -0.5rem;
          z-index: 6;
        }
      }
    }
  }

  .or__divider {
    width: 100%;
    position: relative;
    margin: 0.7rem 0 0 0;

    & > div {
      width: 100%;
      background-color: var(--lightGrayPrimary);
      height: 1px;
      position: absolute;
      top: 35%;
      z-index: 1;
    }
    & > p {
      z-index: 2;
      background-color: #fff;
      padding: 0 10px;
    }
  }

  .buttons__wrap {
    margin-top: 1.5rem;
  }

  ::placeholder {
    font-size: 0.9rem;
  }
  .ant-form-item {
    .ant-select-selection-placeholder {
      margin-top: 0.7rem;
    }

    .ant-select-selection-search {
      input {
        font-size: 1rem;
      }
    }
  }
`;
