import axios from "axios";
import { instance } from "./axios";

export const signupAdvertiser = async (payload: Record<string, any>) => {
  const { data } = await instance.post("auth/register/advertiser", payload);
  return data;
};

export const loginAdvertiser = async (payload: Record<string, any>) => {
  const { data } = await instance.post("auth/login/advertiser", payload);
  return data;
};

export const verifyAdvertiser = async (payload: Record<string, any>) => {
  const { data } = await instance.post("auth/approve/advertiser", payload);
  return data;
};

export const resendAdvertiserVerificationToken = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post("auth/register/resend-confirm", payload);
  return data;
};

export const updateAdvertiserBasicInfo = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.patch("advertiser-info/update-info", payload);
  return data;
};

export const updateAdvertiserSocialLinks = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.patch(
    "advertiser-info/update-social-links",
    payload
  );
  return data;
};

export const updateAdvertiserProfile = async (payload: Record<string, any>) => {
  const { data } = await instance.patch("user/profile", payload);
  return data;
};

export const userVerifyBvn = async (payload: Record<string, any>) => {
  const { data } = await instance.post("user/submit-bvn", payload);
  return data;
};

export const submitBankAccountDetails = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post(
    "user/advertiser/submit-bank-account",
    payload
  );
  return data;
};

export const verifyBankAccountDetails = async (
  payload: Record<string, any>
) => {
  const { data } = await instance.post("bank/validate-account", payload);
  return data;
};

export const getLoggedInAdvertiser = async () => {
  const { data } = await instance.get("user");
  return data;
};

export const createUserAvatarUrl = async (payload: Record<string, any>) => {
  const { data } = await instance.post(
    `user/create-presigned-avatar-url`,
    payload
  );
  return data;
};

export const uploadAvatarToS3 = async (url: string, file: any) => {
  const res = await axios({
    method: "put",
    url: url,
    data: file,
    headers: {
      "Content-Type": file.type,
    },
  });
  return res.status;
};
export const advertiserFollowUser = async (payload: Record<string, any>) => {
  const { data } = await instance.patch(`users/follow`, payload);
  return data;
};
