import React, { useState } from "react";
import { Flex, Form } from "antd";
import { AddShekelModalWrap } from "../sling.styles";

interface IProps {
  setTrxBreakdownModal: any;
  trxBreakdownlModal: {
    isOpen: boolean;
    amount: number;
  };
}
const TrxBreakdownModal: React.FC<IProps> = ({
  setTrxBreakdownModal,
  trxBreakdownlModal,
}) => {
  const { amount } = trxBreakdownlModal;
  const originalAmt = Math.floor(amount / 0.7);

  const commision = (originalAmt / 100) * 30;
  const charges = originalAmt * 0.005;
  const amountToCredit = originalAmt - commision;

  return (
    <AddShekelModalWrap
      open={trxBreakdownlModal.isOpen}
      onCancel={() =>
        setTrxBreakdownModal({ ...trxBreakdownlModal, isOpen: false })
      }
      footer={null}
      centered
    >
      <Flex vertical align="start" className="adshekels__headersection">
        <h3 className="adshekels__header">Transaction breakdown</h3>
      </Flex>
      <div className="shekel_breakdown">
        <Flex justify="stretch" gap={16} vertical>
          <Flex justify="stretch" gap={16} vertical>
            <Flex justify="space-between" align="center">
              <p>Amount funded:</p>
              <p>
                ₦{originalAmt.toLocaleString()} (
                {(originalAmt / 200).toLocaleString()} shekels)
              </p>
            </Flex>
            <Flex justify="space-between" align="center">
              <p>Commission Rate (30%):</p>
              <p>
                ₦{commision.toLocaleString()} (
                {(commision / 200).toLocaleString()} shekels)
              </p>
            </Flex>
            <Flex justify="space-between" align="center">
              <p>Payment charges (0.5%):</p>
              <p>₦{charges}</p>
            </Flex>
          </Flex>
          <div className="shekel_breakdown_line" />
          <Flex justify="space-between" align="center">
            <p className="shekel_breakdown_summary">
              Amount Credited to Sling:
            </p>
            <p className="shekel_breakdown_summary">
              {" "}
              ₦{amountToCredit.toLocaleString()} (
              {(amountToCredit / 200).toLocaleString()} shekels)
            </p>
          </Flex>
        </Flex>
      </div>
    </AddShekelModalWrap>
  );
};

export default TrxBreakdownModal;
